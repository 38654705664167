var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-auto mt-3",staticStyle:{"max-width":"90%"}},[_c('v-layout',{staticStyle:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"pl-3",staticStyle:{"word-break":"break-word !important"}},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mb-3 search-field",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":"","solo":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-slide-x-transition',[_c('div',[_c('v-layout',{staticClass:"mb-3"},[_c('v-card',{staticClass:"pa-3",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"md":"auto"}},[_c('year-picker',{on:{"input":_vm.chengeYear},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"auto"}},[_c('sem-picker',{on:{"input":_vm.chengeSem},model:{value:(_vm.sem),callback:function ($$v) {_vm.sem=$$v},expression:"sem"}})],1),_c('v-col',{attrs:{"md":"auto"}},[_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(" Статистика по: ")]),_c('v-menu',{staticClass:"ml-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(_vm.items.filter(function (f){ return f.value === _vm.value; })[0].text)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"href":("#/Statistics/" + _vm.value),"disabled":_vm.value === item.value},on:{"click":function($event){return _vm.loadVeds(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1)])],1)],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"items-per-page":15,"items":_vm.statistic,"loading":_vm.loading,"search":_vm.search,"headers":_vm.groupsHeaders},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.value == 'k')?_c('a',{attrs:{"href":("#/Directory/Departments/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.name)+" ")],1):_c('a',{attrs:{"href":("#/Directory/Faculties/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.totalStatements",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":("#/DisciplineStatistics/a/" + _vm.year + "/" + _vm.sem + "/" + _vm.value + "/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.totalStatements)+" ")],1)]}},{key:"item.notCompletedCT",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":("#/DisciplineStatistics/nkt/" + _vm.year + "/" + _vm.sem + "/" + _vm.value + "/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.notCompletedCT)+" ")],1)]}},{key:"item.ratingNotCompleted",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":("#/DisciplineStatistics/nr/" + _vm.year + "/" + _vm.sem + "/" + _vm.value + "/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.ratingNotCompleted)+" ")],1)]}},{key:"item.notClosed",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":("#/DisciplineStatistics/u/" + _vm.year + "/" + _vm.sem + "/" + _vm.value + "/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.notClosed)+" ")],1)]}},{key:"item.empty",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"white-space":"nowrap"},attrs:{"href":("#/DisciplineStatistics/e/" + _vm.year + "/" + _vm.sem + "/" + _vm.value + "/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.empty)+" ")],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }