<template>
  <div style="max-width: 90%;" class="ma-auto mt-3">
    <v-layout style="max-width: 100%;">
      <v-card-title class="pl-3" style="word-break: break-word !important;">
        {{getTitle}}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
    <v-container>
              <v-row>
        <v-col>
          <v-card>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="items"
                :items-per-page="15"
                :search="search"
              >
              <template v-slot:item.debts="{ item }" >
              <a  :href='`#/Debt/DebtByStudent/${group}/${sem==1?1:2}/${item.studID}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.debts}}
              </a>
            </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>

<script>

import common from "../../helpers/common";
import veds from "@/helpers/api/veds";

export default {
  name: "StudentsDebts",
  data () {
    return {
      search:"",
      loading: true,
      groupName:"",
      year: common.getCurrentYear(),
      items:[],
    }
  },

  computed: {
      headers() {
          return [
            { text: 'Номер Зачетной Книжки', value: 'recordBookNumber' },
            { text: 'Основания', value: 'grounds' },
            { text: 'Долгов', value: 'debts' },
          ]
      },

      mode () {
        return this.$route.params.mode === undefined ? null : this.$route.params.mode
      },

      group () {
        return this.$route.params.group === undefined ? null : this.$route.params.group
      },

      sem () {
        return this.$route.params.sem === undefined ? null : this.$route.params.sem
      },

      getTitle(){
              return "Cписок студентов группы " + this.groupName +" с задолженностями за "+(this.sem==1?"осенний":"весениий")+" семестр "+this.year+" учебного года"
      }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load(){ 
      this.loading = true;
      var res;
      if(this.sem == 1)
      {
        res = await veds.DebtStatisticsByStudent(this.group,1,this.mode)
      }
      else
      {
        res =await veds.DebtStatisticsByStudent(this.group,2,this.mode)
      }

      this.items = res.res

      this.groupName = res.groupName.groupName
      
      this.loading = false;
    }
  },
}
</script>

<style scoped>

</style>