<template>
  <div style="max-width: 90%;" class="ma-auto mt-3">
    <v-layout style="max-width: 100%;">
      <v-card-title class="pl-3" style="word-break: break-word !important;">
        {{getTitle}}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
    <v-slide-x-transition>
      <div >
        <v-layout  class="mb-3">
          <v-card style="width: 100%;" class="pa-3">
            <v-row>
              <v-col md="auto"><year-picker v-model="year" v-on:input="chengeYear"></year-picker> </v-col> <!--Спросить у Олега как реализовать смену года и семестра-->
              <v-col md="auto"><sem-picker v-model="sem" v-on:input="chengeSem"></sem-picker>  </v-col>
              <v-col md="auto">
                <div>
                  <span class="mr-2">
                  Статистика по:
                  </span>
                  <v-menu class="ml-2" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" small>
                        <span>
                          {{items.filter(f=>f.value === value)[0].text}}
                        </span>
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item
                          @click="loadVeds(item.value)"
                          :href='`#/Statistics/${value}`'
                          v-for="(item, index) in items"
                          :key="index"
                          :disabled="value === item.value"
                      >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>                       
          </v-card>
        </v-layout>

        <v-card>
          <v-data-table  :items-per-page="15" :items="statistic" :loading="loading"  :search="search"  :headers="groupsHeaders">
            <template v-slot:item.name="{ item }">
              <a :href='`#/Directory/Departments/${item.id}`' v-if="value == 'k'">
                <v-icon left>mdi-link</v-icon>
                {{item.name}}
              </a>
              <a :href='`#/Directory/Faculties/${item.id}`' v-else>
                <v-icon left>mdi-link</v-icon>
                {{item.name}}
              </a>
            </template>
            <template v-slot:item.totalStatements="{ item }">
              <a style="white-space: nowrap;" :href='`#/DisciplineStatistics/a/${year}/${sem}/${value}/${item.id}`'>  
                <v-icon left>mdi-link</v-icon>
                {{item.totalStatements}}
              </a>
            </template>
            <template v-slot:item.notCompletedCT="{ item }">
              <a style="white-space: nowrap;" :href='`#/DisciplineStatistics/nkt/${year}/${sem}/${value}/${item.id}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.notCompletedCT}}
              </a>
            </template>
            <template v-slot:item.ratingNotCompleted="{ item }">
              <a style="white-space: nowrap;" :href='`#/DisciplineStatistics/nr/${year}/${sem}/${value}/${item.id}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.ratingNotCompleted}}
              </a>
            </template>
            <template v-slot:item.notClosed="{ item }">
              <a style="white-space: nowrap;" :href='`#/DisciplineStatistics/u/${year}/${sem}/${value}/${item.id}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.notClosed}}
              </a>
            </template>
            <template v-slot:item.empty="{ item }">
              <a style="white-space: nowrap;" :href='`#/DisciplineStatistics/e/${year}/${sem}/${value}/${item.id}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.empty}}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>
import YearPicker from "@/components/YearPicker";
import SemPicker from "@/components/SemPicker";
import common from "../../helpers/common";
import veds from "@/helpers/api/veds";

export default {
  
  name: "Statistics",

  components: {YearPicker, SemPicker},
  data () {
    return {
      year: common.getCurrentYear(),
      sem:1,
      search:"",
      items: [
        {value: 'k', text: 'кафедрам'},
        {value: 'f', text: 'факультетам'},
      ],
      value:"",
      loading:false,
      statistic:[]
    }
  },
  computed: {
    groupsHeaders () {
      return [
        {text: 'Номер', value: 'number'},
        {text: 'Название', value: 'name'},
        {text: 'Всего ведомостей', value: 'totalStatements'},
        {text: 'Не заполнена КТ', value: 'notCompletedCT'},
        {text: 'Не заполнен рейтинг	', value: 'ratingNotCompleted'},
        {text: 'Не закрыто', value: 'notClosed'},
        {text: 'Пустых', value: 'empty'},
      ]
    },

    mode () {
      return this.$route.params.mode === undefined ? null : this.$route.params.mode
    },

    getTitle(){
       switch (this.mode) {
          case 'k':
            this.value="k"
            return "Статистика по кафедрам за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебный год"
          case 'f':
            this.value="f"
            return "Статистика по факультетам за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебный год"
          default:
            return "Статистика"
        }
     }
  },

  mounted() {
    this.loadVeds()
  },

  methods: {
    async loadVeds (item) {
      this.loading = true     

      if(item == undefined) 
      {
        this.value="k"
      } 
      else
      {
        this.value = item
      }    

      this.statistic = await veds.getStatVeds(this.year, this.sem, this.value)   
      
      this.loading = false
    },

        chengeSem(e){
      this.sem=e     
      this.loadVeds (this.mode)
    },
    chengeYear(e){
      this.year=e
      this.loadVeds (this.mode)
    }
  }
}
</script>

<style scoped>

</style>