<template>
  <div style="max-width: 1000px;" class="ma-auto mt-3">
    <v-layout style="max-width: 100%;">
      <v-card-title class="pl-3" style="word-break: break-word !important;">
        {{title}}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
    <v-slide-x-transition>
      <div >
        <v-layout  class="mb-3">
          <v-card style="width: 100%;" class="pa-3">
            <v-row>
              <v-col md="auto"><year-picker v-model="year"></year-picker> </v-col>
              <v-col md="auto"><sem-picker v-model="sem"></sem-picker>  </v-col>
            </v-row>                       
          </v-card>
        </v-layout>

        <v-card>
          <v-data-table  :items-per-page="15" :items="statVedomostiAll" :loading="loading"  :search="search"  :headers="groupsHeaders">
            <template v-slot:item.kafId="{ item }" >
              <a style="white-space: nowrap;" :href='`#/Directory/Departments/${item.kafId}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.kafId}}
              </a>
            </template>
            <template v-slot:item.name="{ item }" >
              <a  :href='`#/Veds/${0}/${item.id}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.name}}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>
import YearPicker from "@/components/YearPicker";
import SemPicker from "@/components/SemPicker";
import common from "../../helpers/common";
import veds from "@/helpers/api/veds";

export default {
  
  name: "DisciplineStatistics",

  components: {YearPicker, SemPicker},
  data () {
    return {
      search:"",
      value:"",
      loading:false,
      statVedomostiAll:[],
      depatmens:[],
      title:""
    }
  },
  computed: {
    groupsHeaders () {
      if(this.stract != 'k')
      {
                return [
        {text: 'Номер кафедры', value: 'kafId'},
        {text: 'Название ведомости', value: 'name'},
        {text: 'Преподаватель', value: 'teacher'},
      ]
      }
      else
      {
              return [
        {text: 'Название ведомости', value: 'name'},
        {text: 'Преподаватель', value: 'teacher'},
      ]
      }

    },

    mode () {
      return this.$route.params.mode === undefined ? null : this.$route.params.mode
    },

    year () {
      return this.$route.params.year === undefined ? null : this.$route.params.year
    },

    sem () {
      return this.$route.params.sem === undefined ? null : parseInt(this.$route.params.sem)
    },

    stract () {
      return this.$route.params.stract === undefined ? null : this.$route.params.stract
    },

    id () {
      return this.$route.params.id === undefined ? null : this.$route.params.id
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    async loadDiscipline () {
      this.loading = true     
      var data = await veds.getStatDiscipline(this.year, this.sem, this.mode, this.stract, this.id)   
      this.statVedomostiAll = data.statVedomostiAll;
      this.depatmens = data.listKafs!= undefined ? data.listKafs : data.listFaculty;
      this.loading = false
    },

    async init()
    {
      await this.loadDiscipline()
      await this.namePageFunc()
    },

    
    namePageFunc(){     
        switch (this.mode) {
          case 'a':
            this.title = "Список всех ведомостей " + (this.stract =="k" ? "кафедры " + this.depatmens[0].kafedraShortName: "факультета " + this.depatmens[0].facultyShortName) +" за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебного года"
            break;
          case 'u':
            this.title = "Список незакрытых вовремя ведомостей " + (this.stract =="k" ? "кафедры" + this.depatmens[0].kafedraShortName: "факультета " + this.depatmens[0].facultyShortName) +" за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебного года"
            break;
          case 'e':
            this.title = "Список пустых ведомостей " + (this.stract =="k" ? "кафедры" + this.depatmens[0].kafedraShortName: "факультета " + this.depatmens[0].facultyShortName) +" за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебного года"
            break;
          case 'nr':
            this.title = "Список ведомостей " + (this.stract =="k" ? "кафедры" + this.depatmens[0].kafedraShortName: "факультета " + this.depatmens[0].facultyShortName) +" с незаполненным вовремя рейтингом за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебного года"
            break;
          case 'nkt':
            this.title = "Список ведомостей " + (this.stract =="k" ? "кафедры" + this.depatmens[0].kafedraShortName: "факультета " + this.depatmens[0].facultyShortName) +" с незаполненным вовремя рейтингом по контрольной точке за " + (this.sem == 1 ? "осенний семестр ": "весенний семестр ") + this.year + " учебного года"
            break;
          default:
            this.title = "Статистика " + (this.stract =="k" ? "кафедры" + this.depatmens[0].kafedraShortName: "факультета")
            break;
        }
    },
  }
}
</script>

<style scoped>

</style>